import React from 'react'
import Layout from "../../components/layout"
import BackButton from '../../components/BackBtn'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function project1({data}) {
    
    return (
        <Layout>
            <main className="main-content main-documentaires">
                <div className="title-container">
                   <h1 className="main-title">Project 1: Als huizen konden praten</h1>
                   <BackButton path="../" text="Terug" />
                </div>
                <p className="main-description">
                    Ik ben begonnen met het documenteren van de Blekerstraat en Lange Hagelstraat in Utrecht. Een
                    klein buurtje ingeklemd tussen spoor, Westplein, Daalse tunnel en Leidseveer tunnel. In de periode
                    oktober 2021 tot nu heb ik straatopnamen gemaakt, het Utrechts Archief (online) geraadpleegd en
                    contact gemaakt met Mitros de verhuurder van de meeste woningen. Van één bewoner zijn tuin en
                    gang gefotografeerd. Ik heb nog geen verhalen of portretten. Dat komt in de volgend fase. Evenals de
                    plannen voor de buurt in de toekomst. En archief onderzoek van Mitros zelf.
                </p>

                <div className="photo-carousel">
                    <Carousel>
                    {data.allFile.nodes.map(image => {
                        return (
                            <Carousel.Item key={image.id} interval={5000} >
                                <GatsbyImage 
                                    image={image.childImageSharp.gatsbyImageData} 
                                    alt={image.base.split('-').join(' ').split('.')[0]}
                                />
                                <Carousel.Caption>
                                    <p className="banner-text">{image.base.split('-').join(' ').split('.')[0]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                        })}
                    </Carousel>
                </div>
            </main>
        </Layout>
    )
}

export const getImages = graphql`
query project1 {
    allFile(filter: {relativeDirectory: {eq: "projecten/1-huizen"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        base
        id
      }
    }
  }
`



